<template>
  <div>
      <ContainerManagement></ContainerManagement>
  </div>
</template>

<script>
import ContainerManagement from '@/components/Dashboard/Complaints/ComplaintManagement/ContainerManagement'
export default {
  components: { ContainerManagement },

}
</script>

<style>

</style>