<template>
  <div class="w-full mb-4 bg-white rounded-xl shadow-lg text-gray-600" v-if="complaint">
      <div class="px-6 py-2 rounded-lg flex justify-between items-center cursor-pointer p-3" @click="toggleAccordion" style="border-bottom: gray solid 1px">
        <p class="text-dark text-left"><b>Monitoreo de la respuesta</b></p>
        <span class="h-5 w-5 m-2 flex justify-center items-center transition" :class="{'transform rotate-180': accordionOpen}">
          <img src="@/assets/icons/arrow-down.svg" alt="">
        </span>
      </div>
      <div v-if="accordionOpen" class="py-3 px-6">
         <div class="flex flex-row gap-2">
            <div class="w-full grid grid-cols-2 justify-between gap-10">
                <BaseSelectNumber v-if="!criterion_id" ref="entity" id="entity" label="Criterios" :selectOptions="formOptions.optionsCriterions" v-model="form.complaints_criterion" required="true"/>
                <BaseInput v-if="criterion_id" type="text" label="Criterio" v-model="criterion_name" placeholder="" :disabled="true"/>
                <BaseRadio ref="revised" name="revised" label="¿Revisado?" :radioOptions="optionsRevised" v-model="form.revised" :disabled="form.completed"/>
            </div>
        </div>
        <div class="flex flex-row gap-2">
            <div class="w-11/12 grid grid-cols-2 gap-2">
                <BaseRadio ref="supplies" name="supplies" label="¿Insumo de supervisión?" :radioOptions="optionsSupplies" v-model="form.supplies" :disabled="form.completed"/>
                <BaseInputCustom v-if="form.revised" type="text" label="Tiempo destinado para la gestión(HH:mm)" v-model="form.evaluation_time" placeholder="00:00:00" :disabled="form.completed"/>
                
            </div>
        </div>
        <div class="flex flex-row gap-2 w-full">
            <div class="w-11/12 grid  gap-2">
                <BaseTextarea ref="describe" id="describe" label="Comentarios adicionales" placeholder="Mensaje" v-model="form.additional_comments" :disabled="form.completed" maxlength="1000"/>
            </div>
        </div>
         <div v-if="!form.completed" class=" flex mt-6 mb-4 flex justify-end gap-5 w-11/12">
            <a-button type="danger" @click="$router.go(-1)" ghost shape="round" :block="true" :loading="loading"  class="w-1/4">
                <span class="mx-2">CANCELAR</span>
            </a-button>
            <a-button type="primary" @click="saveForm" shape="round" :block="true" :loading="loading" class="w-1/4">
                <span class="mx-2">GUARDAR</span>
            </a-button>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  props: ['complaint', 'criterion', 'criterion_id'],
  data(){
      return {
          loading: false,
        form: {
            id: null,
            complaints_queja: null,
            complaints_criterion: "",
            revised: false,
            supplies: false,
            evaluation_time: null,
            additional_comments: "",
            completed: false
        },
        criterion_name: "",
        accordionOpen: false,
        optionsRevised: [
                {id: true, name: 'Sí'},
                {id: false, name: 'No'}
                ],
        optionsSupplies: [
                {id: true, name: 'Sí'},
                {id: false, name: 'No'}
                ],
        criterion_list: []
      }
  },
  watch: {
      'form.complaints_criterion': function(){
          try {
              const actual_evaluation = this.criterion_list.find(item => item.complaints_criterion === this.form.complaints_criterion);
              
              if(actual_evaluation) {
                  this.form = {...this.form,
                  complaints_queja: actual_evaluation.complaints_queja,
                  revised: actual_evaluation.revised,
                  supplies: actual_evaluation.supplies,
                  evaluation_time: actual_evaluation.evaluation_time,
                  additional_comments: actual_evaluation.additional_comments,
                  completed: actual_evaluation.completed
                  }
                } else {
                    this. cleanForm();
                }
          } catch(e) {
              console.error(e)
          }
      }
  },
  methods: {
      toggleAccordion(){
        this.accordionOpen = !this.accordionOpen; 
      },
      async saveForm() {
            if(!this.checkForm()) { 
                return;
            }
            this.form.complaints_queja = this.$props.complaint.codigo_queja;

            this.loading = true;
            let {error, data} = await this.$api.saveManagement(this.form);
            this.loading = false;

            if (error) this.showToast('error', 'Error registrando el criterio. Ya revisado antes.');

            if (data) {
                this.showToast('success', 'El criterio registrado correctamente.');
                const index = this.$props.criterion.findIndex(item => item.complaints_criterion === data.complaints_criterion);
                if (index === -1 ) {
                    this.$props.criterion.push({...data, completed: data.revised ? true: false});
                } else {
                    this.$props.criterion[index] = {...data, completed: data.revised ? true: false};
                }
                // Actualizamos el valor del form con la respuesta del servidor
                this.form = {...data, completed: data.revised ? true: false};
            }
      },
      checkForm() {
          if (!this.form.complaints_criterion) {
            this.showToast('error', 'El criterio es obligatorio');
            return false;
          }
          if (this.form.revised && !this.form.evaluation_time) {
            this.showToast('error',  'El campo tiempo de evaluación es obligatorio si es revisado');
            return false;
          }
          return true;
      },      
      cleanForm() {
          this.form = {...this.form,
            complaints_queja: null,
            revised: false,
            supplies: false,
            evaluation_time: null,
            additional_comments: "",
            completed: false
        }
      },
        showToast(result = 'info', msg) {
          this.$notification[result]({
                message: 'Resultado',
                description: msg,
                onClick: () => {},
            });
        },
  },
  mounted() {
      try {
          // Lista local para actualizar los datos
          this.criterion_list = this.$props.criterion;
          
          // Si se pasa el parametro se debe precargar esos datos
          if (this.$props.criterion_id) {
              const obj_criterion = this.$props.criterion.find(item => item.complaints_criterion == this.$props.criterion_id);
              if (obj_criterion) {
                  this.form.complaints_criterion = obj_criterion?.complaints_criterion;
                  this.form = {...this.form,
                  complaints_queja: obj_criterion.complaints_queja,
                  revised: obj_criterion.revised,
                  supplies: obj_criterion.supplies,
                  evaluation_time: obj_criterion.evaluation_time,
                  additional_comments: obj_criterion.additional_comments,
                  completed: obj_criterion.completed
                    }
                this.criterion_name = obj_criterion?.criterion_name;
                } else {
                    const obj_criterion = this.formOptions.optionsCriterions.find(item => item.id == this.$props.criterion_id);
                    this.form = {
                        ...this.form,
                        complaints_criterion: obj_criterion?.id
                    }
                this.criterion_name = obj_criterion?.name;
                }
        }
      } catch(e){
          console.error(e)
          this.criterion_name = "No se encontró el criterio";
      }  
  },
  computed: {
        formOptions: function(){
            return this.$store.state.form;
        },
  }
}
</script>

<style>

</style>